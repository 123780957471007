/**
 * Main class for integration-specific code.
 * NOTE: This file uses a significantly different structure and code style than the rest of the JS files in this project. The reason for this is that we have opted to try and support old IE versions (8 and newer) in integrations and our normal code structure wont't work for that.
 */

window.MatOchMat = function( args ) {

	/**
	 * Keeps an internal reference to the current instance.
	 */
	var instance = {};

	// No arguments at all
	if ( typeof args === 'undefined' ) {

		args = {};
	}

	/**
	 * Class constructor.
	 */
	instance.constructor = function( args ) {

		try {
			// Class "constants"
			instance.DATA_STRUCTURE__OBJ_SWEDISH_DAYNAMES = 'swedishDaynames';
			instance.DATA_STRUCTURE__OBJ_ENGLISH_DAYNAMES = 'englishDaynames';
			instance.DATA_STRUCTURE__OBJ_DAYS_BY_NUMBER   = 'daysByNumber';
			instance.DATA_STRUCTURE__ARRAY                = 'array';

			instance.INTEGRATION_TYPE__LEGACY   = 1;
			instance.INTEGRATION_TYPE__STANDARD = 2;
			instance.INTEGRATION_TYPE__CUSTOM   = 3;

			// Error messages
			instance.messages = {
				'prefix': 'MatOchMat:',
				'errors': {
					'noSiteURL':                  'No valid site URL could be found. Please specify it manually by setting the data-baseurl attribute on the main script tag.',
					'invalidSelector':            'The specified selector doesn\'t seem valid. Make sure you passed a selector and not for instance a jQuery object.',
					'elementNotFound':            'The element selector you passed seems valid, but the element couldn\'t be found. Are you sure you didn\'t misspell it?',
					'criticalDataMissing':        'You\'re missing some of the data needed to complete this action.',
					'noDataToInsert':             'There was no data to insert. If you\'re using a custom setup: Did you run all the necessary steps?',
					'noRestaurantID':             'You must provide a restaurant ID.',
					'notAllowedBeforeFullySetup': 'Something wasn\'t setup completely before running first trying to run functions. This may sometimes be due to caching plugins downloading our script instead of loading it dynamically.',
					'invalidDataStructure':       'The data structure you requested isn\'t available.',
					'missingURLForRequest':       'To do a request you need to specify a URL.',
					'couldNotInsertLunchMenu':    'Lunch menu could not be inserted.',
					'noDayInfoAvailable':         'No information could be found for the given day. Make sure you called `getData` before trying this and that you are using a valid day ',
					'couldNotGetData':            'Data could not be successfully fetched.',
					'customJSError':              'A JavaScript error occured in the custom JS code.',
					'requests': {
						'timeout': 'Request timed out.',
						'error':   'Error when doing request.',
					},
				}
			};

			// No arguments at all
			if ( typeof args === 'undefined' ) {

				// Setup as object
				args = {};
			}

			// Allow skipping of main stylesheet
			if ( typeof args.skipMainStylesheet === 'undefined' ) {

				args.skipMainStylesheet = false;
			}

			// Allow using functional programming instead of OOP when setting data structure
			if ( typeof args.dataStructure === 'undefined' ) {

				args.dataStructure = instance.DATA_STRUCTURE__OBJ_SWEDISH_DAYNAMES;
			}

			// Special object that allows us to replay functionality after completely reloading the script
			instance.missedFunctionLog = [];

			// Functionality
			instance.autoReloadEnabled = false;
			instance.columnBreakpoint = 700;
			instance.dataStructure = args.dataStructure;
			instance.getDataParams = {
				endpoint: '',
				restaurants: [],
			};
			instance.IEVersion = false;
			instance.insertHTMLWhere = 'beforeend';
			instance.isOldIE = false;
			instance.RESTAPIMenuEndpoint = 'menu';
			instance.restaurantLastUpdatedTimestamps = [];
			instance.setupDone = false;
			instance.skipMainStylesheet = args.skipMainStylesheet;
			instance.targetElementBreakpoint = 300;

			// Class names & IDs
			instance.additionalScriptElementID = 'matochmat-additional-script-tag';
			instance.loadingElementClass = 'matochmat-loading-text';
			instance.menuColumnBreakpointClass = 'column-layout';
			instance.menuColumnClass = 'menu-column';
			instance.scriptElementID = 'matochmat-script-tag';
			instance.targetElementAboveBreakpointClass = 'side-by-side-columns';
			instance.targetElementBreakpointClass = 'full-width-columns';
			instance.targetElementClass = 'matochmat-wrap';

			// Selectors
			instance.scriptElementSelector                = '#' + instance.scriptElementID;
			instance.targetElementSelector                = '.' + instance.targetElementClass;
			instance.loadingElementSelector               = '.' + instance.loadingElementClass;
			instance.targetElementBreakpointSelector      = '.' + instance.targetElementBreakpointClass;
			instance.targetElementAboveBreakpointSelector = '.' + instance.targetElementAboveBreakpointClass;
			instance.menuColumnSelector                   = '.' + instance.menuColumnClass;
			instance.menuColumnBreakpointSelector         = '.' + instance.menuColumnBreakpointClass;

			// Element references
			instance.customWrapperElement = false;
			instance.scriptElement = document.querySelector( instance.scriptElementSelector );
			instance.targetElement = false;

			// Data
			instance.lunchMenuData = false;

			// Check if current browser is an old IE version
			instance.detectOldIE();

			// Only actually run the setup functions if the script tag is present
			if ( document.getElementById( 'matochmat-script-tag' ) ) {

				// Set up our URLs
				instance.setupURLs();

				// Get a reference to our target element
				instance.setElement( instance.targetElementSelector );

				// Load the stylesheet
				instance.loadStylesheet();

				// Indicate that setup is done so we knwo we can safely run functions
				instance.setupDone = true;

			// If it isn't: Then stop everything and try to get it manually, because this might be due to a cache plugin caching OUR file which in turn means we can't get the updated version out whenewer we need it (breaking the entire principle of EVERYTHING being FULLY dynamic).
			} else {

				// Set up our URLs
				instance.setupURLs( true );

				// Create a new script element
				var frameworkScriptElem = document.createElement( 'script' );

				// Set the ID so we don't end up in an infinite loop
				frameworkScriptElem.id  = instance.scriptElementID;

				// Set the source
				frameworkScriptElem.src = instance.siteURL + '/assets/js/integrations.js';

				// Add script element to body
				document.body.appendChild( frameworkScriptElem );

				// Create a new script element
				var instancingScriptElem = document.createElement( 'script' );

				// Set type
				instancingScriptElem.type = 'text/javascript';

				// Save anything in `window.tempMatOchMat` to a variable in case there actually is something there so we can restore it later
				var unlikelyPreviousVariable = window.tempMatOchMat;

				// Assign current instance to a global variable so we can pass it along to the
				window.tempMatOchMat = instance;

				// Create a new script element
				var scriptElem = document.createElement( 'script' );

				// Set type
				scriptElem.type = 'text/javascript';

				// Set the script code we'll use for replaying functions
				var scriptCode = 'var objMatOchMat = new window.MatOchMat(); if ( typeof window.tempMatOchMat.missedFunctionLog === \'object\' && null !== window.tempMatOchMat.missedFunctionLog && 0 < window.tempMatOchMat.missedFunctionLog.length ) { for ( var i = 0; i < window.tempMatOchMat.missedFunctionLog.length; i++ ) { if ( typeof objMatOchMat[ window.tempMatOchMat.missedFunctionLog[ i ].callable ] === \'function\' ) { var objMatOchMatBoundFunction = objMatOchMat[ window.tempMatOchMat.missedFunctionLog[ i ].callable ].bind.apply(objMatOchMat[ window.tempMatOchMat.missedFunctionLog[ i ].callable ], [null].concat(window.tempMatOchMat.missedFunctionLog[ i ].arguments)); objMatOchMatBoundFunction();}}}';

				try {

					scriptElem.appendChild( document.createTextNode( scriptCode ) );

				} catch ( e ) {

					scriptElem.text = scriptCode;
				}

				// Use a `setTimeout` to push this to the bottom of the call stack, allowing out other code to run and add it's stuff to the function log
				setTimeout( function() {

					// Add script element to body
					document.body.appendChild( scriptElem );

					// If our temp variable isn't completely empty
					if ( typeof unlikelyPreviousVariable !== 'undefined' ) {

						// Restore anything that might've been in it
						window.tempMatOchMat = unlikelyPreviousVariable;

					// If it is completely empty
					} else {

						try {

							// Delete if possible
							delete window.tempMatOchMat;

						} catch( e ) {

							// In IE where delete causes errors at least set it as undefined
							window.tempMatOchMat = undefined;
						}
					}
				}, 0 );

			}

		} catch( err ) {

			instance.debug( err );
		}
	};

	/**
	 * Sets up all the URLs we're gonna need. We do this in it's own function so the constructor won't get so extremely cluttered.
	 * @param {boolean} useDefaultURL
	 */
	instance.setupURLs = function( useDefaultURL ) {

		try {

			// Default value for default URL
			if ( typeof useDefaultURL === 'undefined' ) {

				useDefaultURL = false;
			}

			if ( 'string' === typeof instance.scriptElement.getAttribute( 'data-baseurl' ) ) {

				// Run everything through specified data attribute
				instance.siteURL = instance.scriptElement.getAttribute( 'data-baseurl' );
				instance.requestURL = instance.siteURL;

				// Create a temporary link
				var tmpLink = document.createElement( 'a' );

				// Set href of temporary link to the same as the script tag
				tmpLink.href = instance.scriptElement.src;

				// Setup stylesheet URL base
				instance.stylesheetURL = tmpLink.protocol + '//' + tmpLink.hostname + '/';

			// Are we querying the live environment?
			} else if ( true === useDefaultURL || null !== instance.scriptElement.src.match( /(\/\/www\.|\/\/)matochmat\.se/ ) ) {

				// With an old IE version?
				if ( true === instance.isOldIE ) {

					// Load static resources from normal site
					instance.siteURL = 'https://www.matochmat.se/';

					// Is in non-https?
					if ( -1 === window.location.protocol.indexOf( 'https' ) ) {

						// Run AJAX requests through our legacy endpoint
						instance.requestURL = 'http://legacy-api.matochmat.se/';

					// Oh, yeah no, legitimate https then
					} else {

						// Run everything through the normal site
						instance.requestURL = instance.siteURL;
					}

				// Nope, normal browser
				} else {

					// Run everything through the normal site
					instance.siteURL    = 'https://www.matochmat.se/';
					instance.requestURL = instance.siteURL;
				}

				// Setup stylesheet URL base
				instance.stylesheetURL = instance.siteURL;

			// Are we querying the staging environment?
			} else if ( null !== instance.scriptElement.src.match( /staging\.matochmat\.se/ ) ) {

				// With an old IE version?
				if ( true === instance.isOldIE ) {

					// Load static resources from normal site
					instance.siteURL = 'https://staging.matochmat.se/';

					// Is in non-https?
					if ( -1 === window.location.protocol.indexOf( 'https' ) ) {

						// But run AJAX requests through our legacy endpoint
						instance.requestURL = 'http://staging-legacy-api.matochmat.se/';

					// Https
					} else {

						// Run everything through the normal site
						instance.requestURL = instance.siteURL;
					}

				} else {

					// Run everything through the normal site
					instance.siteURL    = 'https://staging.matochmat.se/';
					instance.requestURL = instance.siteURL;
				}

				// Setup stylesheet URL base
				instance.stylesheetURL = instance.siteURL;

			// Localhost or something custom then
			} else {

				throw instance.messages.errors.noSiteURL;
			}

			// Set URLs for stylesheet and the generic REST API URL
			instance.RESTAPIURL    = instance.requestURL + 'rest/';
			instance.stylesheetURL = instance.stylesheetURL + instance.getStylesheetPath();

		} catch( err ) {

			instance.debug( err );
		}
	};

	/**
	 * Perform a request with whatever type of obejct we can access.
	 * @param {string} URL The URL to send request to
	 * @return {Promise} Resolves if request goes OK, rejects if not
	 */
	instance.doRequest = function( URL ) {

		// Return a promise for the request
		return new window.MatOchMatPolyfills.Promise( function( resolve, reject ) {

			try {

				// Make sure we got a URL to use
				if ( typeof URL !== 'string' )
					throw instance.messages.errors.missingURLForRequest;


				// Old IE won't allow us to do CORS-compliant XMLHttpRequests, so use the old XDomainRequest instead
				if ( instance.isOldIE ) {

					// Get a new XDomainRequest object
					var Request = new XDomainRequest();

					// Handler for when the request finishes
					Request.onload = function( response ) {

						// Set to a variable to avoid a freak IE8 edge case where returning the variable directly returns null
						var response = Request.responseText;

						// Resolve the promise. The parameters returned through the promise puprosefully differs from the variables avaiable when running custom integration JS. This is, indeed, intended because when running functions on an instance you should always re-use the current instance. The reason we do it differently for custom integration JS is that we create a new script tag that can't reach any instance (to make sure everything is scoped and won't interfere with the rest of the page) which otherwise wouldn't be able to access instance functions.
						resolve( response );
					}

					// Due to IE(9) being complete garbage as usual we must define onprogress, ontimeout, & onerror functions. In other words: DO NOT TOUCH UNDER ANY FUCKING CIRCUMSTANCES!
					Request.onprogress = function() {};

					Request.ontimeout = function() {

						// Send to debugger
						instance.debug( instance.messages.errors.requests.timeout );

						// Reject the promise
						reject();
					};

					Request.onerror = function() {

						// Send to debugger
						instance.debug( instance.messages.errors.requests.error );

						// Reject the promise
						reject();
					};

				// Normal browsers are, well, normal... so here we can simply use XMLHttpRequest
				} else {

					// Get a new XHR object
					var Request = new XMLHttpRequest();

					// Add a listener for when the request changes status
					Request.onreadystatechange = function( response ) {

						// If request is done
						if ( XMLHttpRequest.DONE == Request.readyState ) {

							// Resolve the promise
							resolve( Request.response );
						}
					}

					// Handler for request timeout
					Request.ontimeout = function() {

						// Send to debugger
						instance.debug( instance.messages.errors.requests.timeout );

						// Reject the promise
						reject();
					}

					// Handler for request error
					Request.onerror = function () {

						// Send to debugger
						instance.debug( instance.messages.errors.requests.error );

						// Reject the promise
						reject();
					}
				}

				// Prepare the request
				Request.open(
					'GET',
					URL,
					true
				);

				// Seems everyone recommends running the send command in a timeout. Haven't seen the need for it yet, but seems like best practice...
				setTimeout( function() {

					// Run it!
					Request.send( null );

				}, 0 );

			} catch( err ) {

				// Log the error so we know something is up
				instance.debug( err );

				// Reject the promise
				return reject();
			}
		});
	};

	/**
	 * Removes the loading text shown before menus are loaded.
	 */
	instance.removeLoadingText = function() {

		try {

			// Set element to a variable
			var loadingTextElem = document.querySelector( '.matochmat-loading-text' );

			// If element hasn't been removed: Remove it now
			if ( null !== loadingTextElem )
				loadingTextElem.outerHTML = '';

		} catch( err ) {

			// Log the error so we know something is up
			instance.debug( err );
		}
	};

	/**
	 * Gets the element that will act as an anchor for our content.
	 * @return {boolean} True if element could be found, False otherwise
	 */
	instance.setElement = function( selector ) {

		try {

			// Make sure we got a selector
			if ( typeof selector !== 'string' ) {

				throw instance.messages.errors.invalidSelector;
			}

			// Set target element to a variable
			instance.targetElement = document.querySelector( selector );

			// If it's an old IE version, add that to the wrapper element so we can use it for CSS selectors
			if ( true === instance.isOldIE && instance.targetElement ) {

				instance.targetElement.className = instance.targetElement.className + ' old-ie';
			}

		} catch( err ) {

			instance.debug( err );
		}
	};

	/**
	 * Load our main stylesheet.
	 */
	instance.loadStylesheet = function() {

		try {

			// Give us a chance to not load the main stylesheet
			if ( true !== instance.skipMainStylesheet ) {

				// Create a stylesheet so we can add the necessary CSS (we're not using $.append or insertAdjacentHTML as IE<=9 rejects the stylesheet if added that way)
				var styleSheet  = document.createElement( 'link' );

				// Set stylesheet properties
				styleSheet.type = 'text/css';
				styleSheet.rel  = 'stylesheet';
				styleSheet.href = instance.stylesheetURL;

				styleSheet.onerror = function() {

					var baseurl = instance.scriptElement.getAttribute( 'data-baseurl' );

					if ( typeof instance.scriptElement.getAttribute( 'data-baseurl' ) !== 'string' || ! baseurl ) {

						return;
					}

					// Create a stylesheet so we can add the necessary CSS (we're not using $.append or insertAdjacentHTML as IE<=9 rejects the stylesheet if added that way)
					var styleSheet = document.createElement( 'link' );

					// Set stylesheet properties
					styleSheet.type = 'text/css';
					styleSheet.rel  = 'stylesheet';
					styleSheet.href = baseurl;

					if ( styleSheet.href.charAt( styleSheet.href.length - 1 ) !== '/' ) {

						styleSheet.href += '/';
					}

					styleSheet.href += instance.getStylesheetPath();

					document.getElementsByTagName( 'head' )[ 0 ].appendChild( styleSheet );
				};

				// Add it to head
				document.getElementsByTagName( 'head' )[ 0 ].appendChild( styleSheet );
			}

		} catch( err ) {

			instance.debug( err );
		}
	};

	/**
	 * Insert the actual HTML contents
	 *
	 * @return     {boolean}  True if HTML could be inserted without errors, false otherwise.
	 */
	instance.insertHTML = function() {

		try {

			// If we're trying to run this before setup is done
			if ( false === instance.setupDone ) {

				// Append to function log
				window.tempMatOchMat.missedFunctionLog.push( { callable: 'insertHTML', arguments: [] } );

				throw instance.messages.errors.notAllowedBeforeFullySetup;
			}

			// Make sure we got all the relevant data
			if ( typeof instance.lunchMenuData === 'undefined' || typeof instance.lunchMenuData.data === 'undefined' || typeof instance.lunchMenuData.data.renderedForIntegration === 'undefined' ) {

				throw instance.messages.errors.noDataToInsert;
			}

			instance.targetElement.insertAdjacentHTML(
				instance.insertHTMLWhere,
				instance.lunchMenuData.data.renderedForIntegration +
				'<hr class="divider" />' +
				instance.lunchMenuData.data.otherRenderedData.menusPresentedByText
			);

			return true;

		} catch( err ) {

			instance.debug( err );

			return false;
		}
	};

	/**
	 * Inserts the integration styling specifically.
	 * @return {boolean} True if HTML could be inserted without errors, false otherwise.
	 */
	instance.insertStyle = function() {

		try {

			// Make sure we got all the relevant data
			if ( typeof instance.lunchMenuData === 'undefined' || typeof instance.lunchMenuData.data === 'undefined' || typeof instance.lunchMenuData.data.renderedForIntegration === 'undefined' ) {

				throw instance.messages.errors.noDataToInsert;
			}

			// If we have integration specific CSS, add that
			if ( typeof instance.lunchMenuData.data.integrationCSS !== 'undefined' ) {

				// Give IE8 special treatment
				if ( true === instance.isOldIE && 8 === instance.IEVersion ) {

					document.createStyleSheet().cssText = instance.lunchMenuData.data.integrationCSS;

				// Handle normal browsers normally
				} else {

					// Create a stylesheet so we can add the necessary CSS (we're not using $.append or insertAdjacentHTML as IE<=9 rejects the stylesheet if added that way)
					var customStyle  = document.createElement( 'style' );

					// Set stylesheet properties
					customStyle.type = 'text/css';

					// Set stylesheet contents
					customStyle.innerHTML = instance.lunchMenuData.data.integrationCSS;

					// Add it to head
					document.getElementsByTagName( 'head' )[ 0 ].appendChild( customStyle );
				}
			}

			return true;

		} catch( err ) {

			instance.debug( err );

			return false;
		}
	}

	/**
	 * Inserts the integration JavaScript specifically.
	 * @return {boolean} True if HTML could be inserted without errors, false otherwise.
	 */
	instance.insertScript = function() {

		try {

			// Make sure we got all the relevant data
			if ( typeof instance.lunchMenuData === 'undefined' || typeof instance.lunchMenuData.data === 'undefined' ) {

				throw instance.messages.errors.noDataToInsert;
			}

			// If we have integration specific JS, add that
			if ( typeof instance.lunchMenuData.data.integrationJS !== 'undefined' ) {

				// Create a new script element
				var scriptElem = document.createElement( 'script' );

				// Set type
				scriptElem.type = 'text/javascript';

				scriptElem.id = instance.additionalScriptElementID;

				// Set some data to local variables so we can temporarily delete them and restore later
				var rendered                          = instance.lunchMenuData.data.rendered;
				var renderedForIntegration            = instance.lunchMenuData.data.renderedForIntegration;
				var integrationCSS                    = instance.lunchMenuData.data.integrationCSS;
				var integrationJS                     = instance.lunchMenuData.data.integrationJS;
				var individualIntegrationCSS          = [];
				var individualProcessedIntegrationCSS = [];
				var individualIntegrationJS           = [];

				// Remove some data as we don't want to bloat the additional variables and objects we'll create for the custom JS
				delete instance.lunchMenuData.data.rendered;
				delete instance.lunchMenuData.data.renderedForIntegration;
				delete instance.lunchMenuData.data.integrationCSS;
				delete instance.lunchMenuData.data.integrationJS;

				// We assume auto reload to have been enabled AFTER this runs for the first time
				if ( true === instance.autoReloadEnabled ) {

					for ( var i = 0; i < instance.lunchMenuData.data.restaurantInfo.length; i++ ) {

						// If the actual RESTAURANT has been updated (and not just the lunch menu) then we may have edited intergation CSS/JS, and as such we reload the entire page
						if ( instance.restaurantLastUpdatedTimestamps[ i ] !== instance.lunchMenuData.data.restaurantInfo[ i ].last_updated ) {

							window.location.reload();
						}
					}
				}

				instance.restaurantLastUpdatedTimestamps = [];

				// Loop restaurants in request
				for ( var i = 0; i < instance.lunchMenuData.data.restaurantInfo.length; i++ ) {

					instance.restaurantLastUpdatedTimestamps.push( instance.lunchMenuData.data.restaurantInfo[ i ].last_updated );

					// Push their data to our temporary arrays
					individualIntegrationCSS.push( instance.lunchMenuData.data.restaurantInfo[ i ].integration_styling );
					individualProcessedIntegrationCSS.push( instance.lunchMenuData.data.restaurantInfo[ i ].processed_integration_styling );
					individualIntegrationJS.push( instance.lunchMenuData.data.restaurantInfo[ i ].integration_javascript );

					// Remove some data as we don't want to bloat the additional variables and objects we'll create for the custom JS
					delete instance.lunchMenuData.data.restaurantInfo[ i ].integration_styling;
					delete instance.lunchMenuData.data.restaurantInfo[ i ].processed_integration_styling;
					delete instance.lunchMenuData.data.restaurantInfo[ i ].integration_javascript;
				}

				// Turn it into JSON so we can send it as a string to the IIFE
				var responseData = JSON.stringify( instance.lunchMenuData );

				// Save anything in `window.tempMatOchMat` to a variable in case there actually is something there so we can restore it later
				var unlikelyPreviousVariable = window.tempMatOchMat;

				// Assign current instance to a global variable so we can pass it along to the
				window.tempMatOchMat = instance;

				// Wrap the code in an IIFE with a try-catch block to minimize impact on the rest of the page
				var scriptCode = '\r\n' +
					'(function(){\r\n' +
						'try {\r\n' +
							'var response = ' + responseData + ';\r\n' +
							'var instance = window.tempMatOchMat;\r\n' +
							'if ( typeof response.data.raw !== \'object\') {\r\n' +
								'response.data.raw = JSON.parse( response.data.raw );\r\n' +
							'}\r\n' +
							'if ( typeof response.data.otherRenderedData.bonusStampInformation !== \'object\' ) {\r\n' +
								'response.data.otherRenderedData.bonusStampInformation = JSON.parse( response.data.otherRenderedData.bonusStampInformation );\r\n' +
							'}\r\n' +
							'if ( typeof response.data.otherRenderedData.printButtons !== \'object\' ) {\r\n' +
								'response.data.otherRenderedData.printButtons = JSON.parse( response.data.otherRenderedData.printButtons );\r\n' +
							'}\r\n' +
							'if ( typeof response.data.restaurantInfo !== \'object\' ) {\r\n' +
								'response.data.restaurantInfo = JSON.parse( response.data.restaurantInfo );\r\n' +
							'}\r\n' +
							'if ( typeof response.data.dateShownOnMainSite.weekDays !== \'object\' ) {\r\n' +
								'response.data.dateShownOnMainSite.weekDays = JSON.parse( response.data.dateShownOnMainSite.weekDays );\r\n' +
							'}\r\n' +
							integrationJS + '\r\n' +
						'} catch( e ) {\r\n' +
							'if ( window.console ) {\r\n' +
								'console.log( \'' + instance.messages.prefix + '\', \'' + instance.messages.errors.customJSError + ' The error was: \' + e );\r\n' +
							'}\r\n' +
						'}\r\n' +
					'})();';

				try {

					scriptElem.appendChild( document.createTextNode( scriptCode ) );

				} catch ( e ) {

					scriptElem.text = scriptCode;
				}

				// Add script element to body
				document.body.appendChild( scriptElem );

				// Restore deleted variables
				instance.lunchMenuData.data.rendered               = rendered;
				instance.lunchMenuData.data.renderedForIntegration = renderedForIntegration;
				instance.lunchMenuData.data.integrationCSS         = integrationCSS;
				instance.lunchMenuData.data.integrationJS          = integrationJS;

				// Loop restaurants in request
				for ( var i = 0; i < instance.lunchMenuData.data.restaurantInfo.length; i++ ) {

					// Restore deleted variables
					instance.lunchMenuData.data.restaurantInfo[ i ].integration_styling           = individualIntegrationCSS[ i ];
					instance.lunchMenuData.data.restaurantInfo[ i ].processed_integration_styling = individualProcessedIntegrationCSS[ i ];
					instance.lunchMenuData.data.restaurantInfo[ i ].integration_javascript        = individualIntegrationJS[ i ];
				}

				// If our temp variable isn't completely empty
				if ( typeof unlikelyPreviousVariable !== 'undefined' ) {

					// Restore anything that might've been in it
					window.tempMatOchMat = unlikelyPreviousVariable;

				// If it is completely empty
				} else {

					try {

						// Delete if possible
						delete window.tempMatOchMat;

					} catch( e ) {

						// In IE where delete causes errors at least set it as undefined
						window.tempMatOchMat = undefined;
					}
				}
			}

			return true;

		} catch( err ) {

			instance.debug( err );

			return false;
		}
	}



	/**
	 * Get the data from the REST API.
	 * @param {string|Array} restaurants Obfuscated restaurant ID or array with obfuscated IDs
	 * @param {string}       endpoint    The endpoint to query
	 * @return {Promise} Resolves if data could be fetched, rejects if not
	 */
	instance.getData = function( restaurants, endpoint ) {

		return new window.MatOchMatPolyfills.Promise( function( resolve, reject ) {

			try {

				// If we're trying to run this before setup is done
				if ( false === instance.setupDone ) {

					// Append to function log
					window.tempMatOchMat.missedFunctionLog.push( { callable: 'getData', arguments: [ restaurants, endpoint ] } );

					throw instance.messages.errors.notAllowedBeforeFullySetup;
				}

				instance.getDataParams = {
					endpoint: endpoint,
					restaurants: restaurants,
				};

				// Instantiate query string as an empty string
				var queryString = '';

				// Single restaurant
				if ( typeof restaurants === 'string' ) {

					queryString = '?restaurant=' + encodeURIComponent( restaurants );

				// Multiple restaurants
				} else if ( typeof restaurants === 'object' ) {

					var restaurantCount = restaurants.length;

					for ( var i = 0; i < restaurantCount; i++ ) {

						if ( 0 === i )
						{
							queryString += '?';
						}
						else
						{
							queryString += '&';
						}

						// Append restaurant in array-structure for GET variables
						queryString += 'restaurant[]=' + encodeURIComponent( restaurants[ i ] );
					}
				}

				// Do a request to get menu data
				instance.doRequest(
					instance.RESTAPIURL + endpoint + '/' + queryString
				).then(
					function( response ) {

						// Parse the data from JSON
						instance.lunchMenuData = JSON.parse( response );

						if ( true == instance.autoReloadEnabled ) {

							var additionalScriptElement = document.getElementById( instance.additionalScriptElementID );

							if ( additionalScriptElement ) {

								additionalScriptElement.remove();
							}

							if ( instance.targetElement ) {

								instance.targetElement.innerHTML = '';
							}

							if ( instance.customWrapperElement ) {

								instance.customWrapperElement.innerHTML = '';
							}
						}

						// If we don't want swedish daynames as keys, convert the structure
						if ( instance.DATA_STRUCTURE__OBJ_SWEDISH_DAYNAMES !== instance.dataStructure ) {

							instance.lunchMenuData.data.raw = instance.convertDataStructure( instance.lunchMenuData.data.raw );
						}

						instance.insertStyle();

						// Push to the bottom of the call stack to run it after whatever is in the promise chain
						setTimeout( function() {

							instance.insertScript();

						}, 0 );

						return resolve( instance.lunchMenuData );
					}
				).catch(
					function() {

						instance.removeLoadingText();
					}
				);

			} catch( err ) {

				instance.debug( err );

				return reject();
			}
		});
	};



	/**
	 * Converts the data structure after running getData so we can more easily handle object, arrays and loop on the integration side of things.
	 * @param {object} data Raw data
	 * @return {object} Processed data
	 */
	instance.convertDataStructure = function( data ) {

		try {

			// Get amount of menus present in data
			var menuCount = data.length;

			for ( var i = 0; i < menuCount; i++ ) {

				// So, what structure do we want?
				switch ( instance.dataStructure ) {

					// Assosciative keys, english day names
					case instance.DATA_STRUCTURE__OBJ_ENGLISH_DAYNAMES: {

						// Replace contents
						data[ i ].content = {
							'monday':    data[ i ].content.mandag,
							'tuesday':   data[ i ].content.tisdag,
							'wednesday': data[ i ].content.onsdag,
							'thursday':  data[ i ].content.torsdag,
							'friday':    data[ i ].content.fredag,
							'saturday':  data[ i ].content.lordag,
							'sunday':    data[ i ].content.sondag,
						};

						break;
					}

					// Assosciative keys, day number as key (1-7)
					case instance.DATA_STRUCTURE__OBJ_DAYS_BY_NUMBER: {

						// Replace contents
						data[ i ].content = {
							'1': data[ i ].content.mandag,
							'2': data[ i ].content.tisdag,
							'3': data[ i ].content.onsdag,
							'4': data[ i ].content.torsdag,
							'5': data[ i ].content.fredag,
							'6': data[ i ].content.lordag,
							'7': data[ i ].content.sondag,
						};

						break;
					}

					// Indexed array (0-6)
					case instance.DATA_STRUCTURE__ARRAY: {

						// Replace contents
						data[ i ].content = [
							data[ i ].content.mandag,
							data[ i ].content.tisdag,
							data[ i ].content.onsdag,
							data[ i ].content.torsdag,
							data[ i ].content.fredag,
							data[ i ].content.lordag,
							data[ i ].content.sondag,
						];

						break;
					}

					// Not a datastructure we actually provide
					default: {

						instance.debug( instance.messages.errors.invalidDataStructure );

						break;
					}
				}
			}

		} catch( err ) {

			instance.debug( err );
		}

		return data;
	};

	/**
	 * Inserts a lunch menu at the designated destination without any customization.
	 * @param {string} restaurants Hashed restaurant ID
	 * @return {Promise} Resolves HTML was inserted, rejects if not
	 */
	instance.insertLunchMenu = function( restaurants ) {

		return new window.MatOchMatPolyfills.Promise( function( resolve, reject ) {

			try {

				// If we're trying to run this before setup is done
				if ( false === instance.setupDone ) {

					window.tempMatOchMat.missedFunctionLog.push( { callable: 'insertLunchMenu', arguments: [ restaurants ] } );

					throw instance.messages.errors.notAllowedBeforeFullySetup;
				}

				// If we found have a target element
				if ( instance.targetElement ) {

					// Make sure we got one or more restaurant IDs
					if ( typeof restaurants !== 'string' && typeof restaurants !== 'object' ) {

						throw instance.messages.errors.noRestaurantID;
					}

					// Remove the fallback for browsers without JS as, obviously, this isn't one of them
					instance.removeNoJSFallback();

					// Get menu, then insert HTML
					instance.getData(
						restaurants,
						instance.RESTAPIMenuEndpoint
					).then(
						function( response ) {

							// Do we want to automatically render it? I.e.: use default layout
							var autoRender = false;

							for ( var i = 0; i < response.data.restaurantInfo.length; i++ ) {

								// Set integration type to a variable for DRY code
								var integrationType = parseInt( response.data.restaurantInfo[ i ].integration_type );

								// If it's a standard integration we obviously want this. BUT! We also really do want this for legacy integrations too since SOME old integrations will be standard and some will not. HOWEVER, since standard will need to go here it's no problem, and since the old custom scripts won't even run this function it's also safe to have this condition. NEW custom scripts WILL however run this function, so we must never add those to the if-case below.
								if ( instance.INTEGRATION_TYPE__STANDARD === integrationType || instance.INTEGRATION_TYPE__LEGACY === integrationType ) {

									// Needs to be autorendered
									autoRender = true;
								}
							}

							// If we wanted to autorender anything
							if ( true === autoRender ) {

								instance.insertHTML();

								instance.setupBreakpointEmulator();
							}

							instance.removeLoadingText();

							return resolve( response );
						}
					).catch(
						function() {

							instance.removeLoadingText();

							return reject();
						}
					);

				// No target element
				} else {

					return reject();
				}

			} catch( err ) {

				instance.debug( err );

				return reject();
			}
		});
	};

	/**
	 * Remove the fallback for browsers without JS as, obviously, this isn't one of them.
	 */
	instance.removeNoJSFallback = function() {

		if ( instance.targetElement ) {

			var fallbackLinks = instance.targetElement.querySelector( '.no-js-fallback' );

			if ( null !== fallbackLinks ) {

				fallbackLinks.outerHTML = '';
			}

			// Set a text so user knows it's loading
			instance.targetElement.innerHTML = '<span class="matochmat-loading-text">Laddar lunchmenyer...</span>';
		}
	}

	/**
	 * Calculates if we need to add any emulated breakpoints and adds listener to repeat on resize.
	 */
	instance.setupBreakpointEmulator = function() {

		try {

			// If we have a target element
			if ( instance.targetElement ) {

				// Set column-count for menu wrapper
				instance.targetElement.setAttribute( 'data-columns', instance.lunchMenuData.data.raw.length );

				// Setup initial breakpoint
				instance.emulateBreakpointsBasedOnAvailableWidth();

				// IE9 is too slow at printing and rendering, so we'll run it again after a bit of time has passed
				setTimeout( function() {

					// Reset breakpoints
					instance.emulateBreakpointsBasedOnAvailableWidth();

				}, 500 );

				// If it's IE8 we'll need to use attachEvent instead of addEventListener
				if ( true === instance.isOldIE && 8 === instance.IEVersion ) {

					window.attachEvent( 'onresize', function() {

						// Reset breakpoints
						instance.emulateBreakpointsBasedOnAvailableWidth();
					});

				// Normal browsers get normal treatment
				} else {

					// Listen for window resizes
					window.addEventListener( 'resize', function() {

						// Reset breakpoints
						instance.emulateBreakpointsBasedOnAvailableWidth();
					});
				}
			}

		} catch( err ) {

			instance.debug( err );
		}
	};

	/**
	 * Emulates breakpoints by adding and removing classes.
	 */
	instance.emulateBreakpointsBasedOnAvailableWidth = function() {

		try {

			// Get menu columns
			var menus = document.querySelectorAll( instance.menuColumnSelector );

			// If not set later: Assume we want as many columns as we have elements
			var targetColumns = menus.length;

			// Do we have more than 4 columns?
			if ( typeof instance.targetElement.getAttribute( 'data-columns' ) === 'string' && 4 <= parseInt( instance.targetElement.getAttribute( 'data-columns' ) ) ) {

				// Use a 2 column grid instead
				targetColumns = 2;
			}

			// If target element width is less than the per-column width we've defined
			if ( 8 !== instance.IEVersion && instance.targetElement.offsetWidth < ( instance.targetElementBreakpoint * targetColumns ) ) {

				// Add the breakpoint class
				instance.addClass(
					instance.targetElement,
					instance.targetElementBreakpointClass
				);

				// Remove the above breakpoint class
				instance.removeClass(
					instance.targetElement,
					instance.targetElementAboveBreakpointClass
				);

			} else {

				// Remove the breakpoint class
				instance.removeClass(
					instance.targetElement,
					instance.targetElementBreakpointClass
				);

				// Add the above breakpoint class
				instance.addClass(
					instance.targetElement,
					instance.targetElementAboveBreakpointClass
				);
			}

			// Loop menu columns
			for ( var i = 0; i < menus.length; i++ ) {

				// Is this column below our breakpoint
				if ( menus[ i ].offsetWidth < instance.columnBreakpoint ) {

					// Add the breakpoint class
					instance.addClass(
						menus[ i ],
						instance.menuColumnBreakpointClass
					);

				// Nope, it's above our breakpoint
				} else {

					// Remove the breakpoint class
					instance.removeClass(
						menus[ i ],
						instance.menuColumnBreakpointClass
					);
				}
			}

		} catch( err ) {

			instance.debug( err );
		}
	};

	/**
	 * Gets info about a given day.
	 * @param {object|array} day Day to get info about.
	 * @return {object|null} Object containing the data for the given day or, if no day was found, simply null.
	 */
	instance.getDayInfo = function( input ) {

		// Start by assuming we didn't find a weekDay
		var weekDay = null;

		try {

			// If we're trying to run this before setup is done
			if ( false === instance.setupDone ) {

				window.tempMatOchMat.missedFunctionLog.push( { callable: 'getDayInfo', arguments: [ input ] } );

				throw instance.messages.errors.notAllowedBeforeFullySetup;
			}

			// Make sure that we have any data at all
			if ( false === instance.lunchMenuData ) {

				throw instance.messages.errors.noDayInfoAvailable;
			}

			// Set weekdays to a local variable for easier reference
			var weekDays = instance.lunchMenuData.data.dateShownOnMainSite.weekDays;

			// Check if object (and object includes arrays, sadly)
			if ( typeof input === 'object' ) {

				// So, is it ACTUALLY an array? And do we have the values we need?
				if ( input instanceof Array && typeof input[ 0 ] !== 'undefined' && typeof input[ 0 ] !== 'undefined' ) {

					// Loop weekdays
					for ( var i = 0; i < weekDays.length; i++ ) {

						// Check if that key exists (just to be sure) and check if the value matches
						if ( typeof weekDays[ i ][ input[ 0 ] ] !== 'undefined' && weekDays[ i ][ input[ 0 ] ] === input[ 1 ] ) {

							// Set value of weekday to this
							weekDay = weekDays[ i ];

							// break the loop
							break;
						}
					}

				// Nope, object then
				} else {

					// Loop weekdays
					for ( var i = 0; i < weekDays.length; i++ ) {

						// Loop parts of object as we cannot use `Object.keys()[ 0 ]` due to IE8 support
						for ( var objKey in input ) {

							// Check if that key exists (just to be sure) and check if the value matches
							if ( typeof weekDays[ i ][ objKey ] !== 'undefined' && weekDays[ i ][ objKey ] === input[ objKey ] ) {

								// Set value of weekday to this
								weekDay = weekDays[ i ];

								// break the loop
								break;
							}

							// Always break after first loop as we only care about the first value (and only loop due to IE8). While not breaking would allow us to use multiple filters, that would be more of an anti-pattern than a feature and might impact performance.
							break;
						}
					}
				}
			}

		} catch( err ) {

			instance.debug( err );
		}

		return weekDay;
	}

	/**
	 * Adds a class to an element.
	 * @param {object} el        Element to add class to
	 * @param {string} className Class to add
	 * @return {object} Input element
	 */
	instance.addClass = function( el, className ) {

		try {

			// Is that class on the element?
			if ( -1 === el.className.indexOf( className ) ) {

				// Add the class
				el.className = el.className + ' ' + className;

				// Make sure we don't add an incrementing number of additional spaces
				el.className = el.className.replace( /\s+/g, ' ' );
			}

		} catch( err ) {

			instance.debug( err );
		}

		// Return the element to allow function chaining
		return el;
	};

	/**
	 * Remove a class from an element.
	 * @param {object} el        Element to remove class from
	 * @param {string} className Class to remove
	 * @return {object} Input element
	 */
	instance.removeClass = function( el, className ) {

		try {

			// Is that class on the element?
			if ( -1 !== el.className.indexOf( className ) ) {

				// Remove the class
				el.className = el.className.split( className ).join( '' );
			}

		} catch( err ) {

			instance.debug( err );
		}

		// Return the element to allow function chaining
		return el;
	};

	/**
	 * Try to detect whether the browser being run is an old version of Internet Explorer.
	 */
	instance.detectOldIE = function() {

		try {

			// Create an empty div and set it to a variable
			var tempElem = document.createElement( 'div' );

			// Add a conditional comment with an empty element into the div
			tempElem.innerHTML = '<!--[if lte IE 9]><i></i><![endif]-->';

			// Check if the browser considers that our new elements child is a valid item meaning it is in fact an old IE version, and set a variable for that
			if ( 0 < tempElem.getElementsByTagName( 'i' ).length ) {

				instance.isOldIE = true;
			}

			// If it is old IE we're gonna want to know which version
			if ( true === instance.isOldIE ) {

				// Clear out innerHTML
				tempElem.innerHTML = '';

				// Add a conditional comment with an empty element into the div that only applies to IE8
				tempElem.innerHTML = '<!--[if lte IE 8]><i></i><![endif]-->';

				// Check if the browser considers responds to that element
				if ( null !== tempElem.getElementsByTagName( 'i' ) && 0 < tempElem.getElementsByTagName( 'i' ).length ) {

					instance.IEVersion = 8;

				} else {

					instance.IEVersion = 9;
				}
			}

		} catch( err ) {

			instance.debug( err );
		}
	};

	/**
	 * Removes double slashes from a given URL.
	 * @param {string} url The url to replace in
	 * @return {string} Processed URL
	 */
	instance.removeDoubleSlashesFromURL = function( url ) {

		if ( typeof url !== 'string' ) {

			return '';

		} else {

			return url.replace( /([^:]\/)\/+/g, '$1' );
		}
	}

	/**
	 * Enables the script to automatically reload data. Note that this is NOT a solution that will work for all sites, and therefore not enabled by default. Enable it in the custom JS and see if it works for you use case before committing to using it.
	 * @param {number} intervalInMs Interval (in MS) between reloads.
	 */
	instance.enableAutoReload = function( intervalInMs ) {

		// Once auto reload is enabled we need't run this (since custom JS will try to trigger this again)
		if ( instance.autoReloadEnabled ) {

			return;
		}

		if ( ! intervalInMs ) {

			intervalInMs = 1000 * 60 * 5;
		}

		instance.autoReloadEnabled = true;

		var reloader = function() {

			setTimeout( function() {

				instance.getData( instance.getDataParams.restaurants, instance.getDataParams.endpoint );

				reloader();

			}, intervalInMs );
		};

		reloader();
	};

	/**
	 * Log debug data to console so we have a way of knowing what went wrong, and to give us a way to easily find and rectify user errors.
	 * @param {string} err The error message
	 */
	instance.debug = function( err ) {

		// Make sure we have a console object (What the actual fuck IE<=9, not even running the rest of the code if devtools isn't open?) and that the error isn't undefined
		if ( window.console && typeof err !== 'undefined' ) {

			console.log(
				instance.messages.prefix,
				err
			);
		}
	};

	instance.getStylesheetPath = function() {

		return 'assets/css/integrations.css';
	}

	// Run the constructor function so it acts as a normal class
	instance.constructor( args );

	return instance;
};
