/**
 * Polyfill handling for integrationsdue to the fact that we're no longer
 * running Babel and cannot inject them into our classes at compile-time.
 */


// Object containing references to the polyfills we need
window.MatOchMatPolyfills = {
	'Promise': Promise,
};
