/*
 * The main JavaScript file for website integrations.
 *
 * IMPORTANT NOTE: Due to the fact that we have decided to support legacy
 * browsers (read: UNREASONABLY old crap) some keywords like `delete *variable*`
 * and `.catch()` for promises can NOT be used, ever. This does mean that we'll
 * get errors and warnings logged to console in modern browsers, but we can
 * never add those pieces of code so just live with it.
 */


// Check if we can access any native or polyfilled Promise and, if so, set that to a variable
var existingPromise = (
	typeof window.Promise !== 'undefined'
		? window.Promise
		: false
);


/*----------  Frameworks  ----------*/

// Promise polyfill
#=require "./../frameworks/promise-polyfill/promise.min.js"



/*----------  Integration specifics  ----------*/

#=require "./integrations/_integrations.polyfills.js"
#=require "./integrations/_integrations.js"



try {

	// If we didn't have a Promise on window, delete it so no one can uses ours and instead have to add their own polyfill (so they don't add us as a dependency if they're careless)
	if ( false === existingPromise ) {

		try {

			// Delete if possible
			delete window.Promise;

		} catch( e ) {

			// In IE where delete causes errors at least set it as undefined
			window.Promise = undefined;
		}

	// If we did have a Promise on window, restore it so it works as before for any other scripts
	} else {

		window.Promise = existingPromise;
	}

} catch( err ) {

	// Log the error, but only if console is accessible
	if ( window.console ) {

		console.log( err );
	}
}
